import './App.css';
import React from 'react';
import TwitchIcon from './icons/twitch-icon.svg';
import DiscordIcon from './icons/discord-icon.svg';
import DashedCircle from './icons/DashedCircle.svg';
import * as THREE from 'three';
import * as model from './bg/model.json';

class App extends React.Component {
	constructor(props){
		super(props);

		this.state = {
			status:"login",
			vtype:null
		};

		this.authenticate = this.authenticate.bind(this);
		this.startVerification = this.startVerification.bind(this);
		this.chooseVerification = this.chooseVerification.bind(this);
		this.submitVerification = this.submitVerification.bind(this);
	}

	componentDidMount(){
		var scene = new THREE.Scene();
		scene.background = new THREE.Color("hsl(200, 100%, 25%)");
		var camera = new THREE.PerspectiveCamera( 60, window.innerWidth / window.innerHeight, 0.1, 1000 );
		camera.position.z = 15;
		var renderer = new THREE.WebGLRenderer();
		var loader = new THREE.ObjectLoader();
		var backgroundSphere = null;
		renderer.setSize(window.innerWidth, window.innerHeight);
		document.querySelector(".background3d").appendChild(renderer.domElement);
		
		loader.parse(model, (obj)=>{
			console.log("OBJ", obj);
			backgroundSphere = obj;
			scene.add(backgroundSphere);
			

			var animateBackground=()=>{
				backgroundSphere.rotation.y += 1/1000;
				requestAnimationFrame(animateBackground);
				renderer.render(scene, camera);
			}
			animateBackground();
		});
	}

	authenticate(e){
		e.preventDefault();

		let formData = new FormData(e.target);
		console.log("AUTHING");
		fetch("/user/authentication",{
			method:"POST",
			headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
			body:JSON.stringify(Object.fromEntries(formData))
		}).then(async response => {
			let res = await response.json();
			console.log(res);
			if(res.status == "active"){
				let params = new URLSearchParams(window.location.search);
				let returnPage = params.get("return");
				if(returnPage == null){
					window.location.href = window.location.origin;
				}else{
					window.location.href = window.location.origin+"/"+returnPage;
				}
			}else{
			this.setState(Object.assign(this.state, {status:res.status}))
			}
			
		})
	}

	startVerification(e){
		this.setState(Object.assign(this.state, {status:"verify"}));
	}

	chooseVerification(e){
		let vType = e.currentTarget.name;
		this.setState(Object.assign(this.state, {status:"verify-"+vType, vtype:vType}));
	}

	submitVerification(e){
		e.preventDefault();
		let vType = document.querySelector(".auth-form [name=vtype]").value;
		let username = document.querySelector(".auth-form [name=username]").value.toLowerCase();
	
		fetch("/user/verify", {
			method:"POST",
			headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
			body:JSON.stringify({
				vtype:vType,
				username:username
			})}
		).then(async response => {
			let res = await response.json();
			if(res.status == "found"){
				this.setState(Object.assign(this.state, {status:"found", vusername:username, vcount:60, 
				vInterval:setInterval((()=>{

					fetch("/user/verifycheck/?username="+username)
					.then(response => response.text())
					.then(status => {
						console.log(status);
						if(status == "verify-complete"){
							clearInterval(this.state.vInterval);
							this.setState(Object.assign(this.state, {status:"verify-complete"}));
						}else if(status == "verify-waiting"){
							this.setState(Object.assign(this.state, {vcount:this.state.vcount-1}));
						}else if(status == "verify-cancelled"){
							clearInterval(this.state.vInterval);
							this.setState(Object.assign(this.state, {status:"verify-cancelled",vcount:null}));
						}
					})
				}).bind(this),1000)}));
			}else{
				this.setState(Object.assign(this.state, {status:"notfound"}));
			}
		})
	}

	verfiyPassword(e){
		let password = document.querySelector(".auth-form [name=password]");
   		let vpassword = document.querySelector(".auth-form [name=vpassword]");
		   let submitButton = document.querySelector(".auth-form [type=submit]");
		if(vpassword.value != ""){
			if(password.value != vpassword.value){
				if(password.classList.contains("match")){
					password.classList.remove("match");
				}
				if(vpassword.classList.contains("match")){
					vpassword.classList.remove("match");
				}
				if(!password.classList.contains("nomatch")){
					password.classList.add("nomatch");
				}
				if(!vpassword.classList.contains("nomatch")){
					vpassword.classList.add("nomatch");
				}
				submitButton.disabled = true;
				console.log("NO MATCH");
			}else{
				if(!password.classList.contains("match")){
					password.classList.add("match");
				}
				if(!vpassword.classList.contains("match")){
					vpassword.classList.add("match");
				}
				if(password.classList.contains("nomatch")){
					password.classList.remove("nomatch");
				}
				if(vpassword.classList.contains("nomatch")){
					vpassword.classList.remove("nomatch");
				}
				console.log("MATCH");
				submitButton.disabled = false;
			}
		}
	}

	render(){
		let mainContent = null;
		if(this.state.status == "login"){
			mainContent =
              <form className="auth-form" onSubmit={this.authenticate}>
                <input type="text" name="username" placeholder="Username"/>
                <input type="password" name="password" placeholder="Password"/>
                <button className="modcheck-button" type="submit">Login</button>
				<button className="modcheck-button" type="button" onClick={this.startVerification}>First Time?</button>
              </form>;
		}else if(this.state.status == "verify" || this.state.status == "verify-cancelled" || this.state.status == "notfound"){
			let statusHeader = null;
			let statusText = null;
			if(this.state.status == "verify-cancelled"){
				statusHeader = <div className="status-header">Timed out...</div>;
				statusText = <div className="status-text">Verification took too long. Try again.</div>
			}else if(this.state.status == "notfound"){
				statusHeader = <div className="status-header">Can't find you</div>;
				statusText = <div className="status-text">Your name doesn't appear on the list of trusted users. Try again or contact my owner.</div>
			}else{
				statusHeader = <div className="status-header">Have we met?</div>;
				statusText = <div className="status-text">If so, my owner must have put your Twitch or Discord name as a trusted user. Which platform will you verify on?</div>
			}
			mainContent = 
				<form className="auth-form">
					{statusHeader}
					{statusText}
					<div className="verify-platforms">
						<button type="submit" name="twitch" className="platform-button" onClick={this.chooseVerification}>
							<img src={TwitchIcon} width={50} height={50}/>
							<span>Twitch</span>
						</button>
						<button type="submit" name="discord" className="platform-button" onClick={this.chooseVerification}>
							<img src={DiscordIcon} width={50} height={50}/>
							<span>Discord</span>
						</button>
					</div>
				</form>;
		}else if(this.state.status == "verify-twitch"){
			mainContent =
				<form className="auth-form" onSubmit={this.submitVerification}>
					<div className="status-header">Verify by Twitch</div>
					<div className="status-text">Alright, enter your Twitch username</div>
					<input type="hidden" name="vtype" value="twitch"/>
					<input type="text" name="username" placeholder="Username"/>
					<button className="modcheck-button" type="submit">Verify</button>
				</form>;
		}else if(this.state.status == "verify-discord"){
			mainContent = 
				<form className="auth-form" onSubmit={this.submitVerification}>
					<div className="status-header">Verify by Discord</div>
					<div className="status-text">Alright, enter your Discord username</div>
					<input type="hidden" name="vtype" value="discord"/>
					<input type="text" name="username" placeholder="Username"/>
					<button className="modcheck-button" type="submit">Verify</button>
				</form>;
		}else if(this.state.status == "verify-complete"){
			mainContent = 
              <form className="auth-form" onSubmit={this.authenticate}>
			  	<div className="status-header">You're Verified!</div>
				<div className="status-text">Now set a username and password for logging in.</div>
                <input type="text" name="username" placeholder="Username" defaultValue={this.state.vusername}/>
                <input type="password" name="password" placeholder="Password" onChange={this.verfiyPassword}/>
				<input type="password" name="vpassword" placeholder="Password" onChange={this.verfiyPassword}/>
				<input type="hidden" name="vtype" value={this.state.vtype}/>
				<input type="hidden" name="vusername" value={this.state.vusername}/>
                <button className="modcheck-button" type="submit" disabled={true}>Login</button>
              </form>;
		}else if(this.state.status == "found"){
			let statusText = null;
			if(this.state.vtype == "twitch"){
				statusText = <div className="status-text">Please go to my owner's Twitch channel and call '!mod verify' to verify.</div>
			}else if(this.state.vtype == "discord"){
				statusText = <div className="status-text">I sent you a message on Discord. Click confirm to verify.</div>
			}
			mainContent = 
              <form className="auth-form">
			  	<div className="status-header">Waiting for verification...{this.state.vcount}</div>
				<div className="loading-icon">
					<img className="loading-circle" src={DashedCircle} width="64px" height="64px"/>
				</div>
              </form>;
		}
		return <div className="App">
					<div className="header">/╲/\( ºO ω Oº )/\╱\</div>
					{mainContent}
					<div className="background3d"></div>
				</div>;
	}
}

export default App;
